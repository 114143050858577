import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
// import About from '../containers/about';
import { Email } from "react-obfuscate-email";
type AboutPageProps = {};

const Blog: React.FunctionComponent<AboutPageProps> = () => {
  return (
    <Layout>
      <SEO
        title="Dutch Tax Calculator Privacy Notice"
        description="Dutch Tax Calculator Privacy Notice"
      />

<div className='privacy-notice p-5'>
<h2>Privacy Notice</h2>
<p>
We respect your privacy and are committed to protecting your personal data. This privacy notice explains how we collect, use, and disclose your personal data when you visit our website.
</p>
<h3>What personal data do we collect?</h3>
<p>
When you visit our website, we may collect certain information about your device, including information about your web browser, IP address, time zone, and some of the cookies that are installed on your device. Additionally, as you browse our website, we may collect information about the individual web pages or products that you view, what websites or search terms referred you to our website, and information about how you interact with our website.
</p>
<p>
We collect this data using cookies. Cookies are small data files that are placed on your device when you visit our website. They help us to provide you with a better user experience and to analyze how our website is being used.
</p>
<p>
We also use Google Analytics to help us understand how our website is being used. Google Analytics collects information about your use of our website, including the pages you visit, the time spent on each page, the links you click on, and other actions you take while using our website. We use this information to improve our website and to provide you with a better user experience.
</p>
<h3>How do we use your personal data?</h3>
<p>
We use the personal data we collect to:
</p>
<ul>
<li>Improve our website</li>
<li>Personalize your experience on our website</li>
<li>Analyze how our website is being used</li>
<li>Communicate with you about our products and services</li>
</ul>
<p>
We do not sell, rent, or otherwise disclose your personal data to third parties, except as described in this privacy notice.
</p>
<h3>Disclosure of your personal data</h3>
<p>
We may disclose your personal data to third-party service providers who perform services on our behalf. These service providers may include website hosting providers, IT service providers, and marketing service providers.
</p>
<p>
We may also disclose your personal data to law enforcement agencies, government bodies, or other third parties if we are required to do so by law or if we believe that such disclosure is necessary to:
</p>
<ul>
<li>Comply with legal obligations</li>
<li>Protect our rights and property</li>
<li>Prevent fraud or other illegal activity</li>
<li>Protect the safety of our users</li>
</ul>
<h3>Your rights</h3>
<p>
You have the right to:
</p>
<ul>
<li>Access the personal data we hold about you</li>
<li>Request that we correct any inaccurate or incomplete personal data</li>
<li>Request that we delete your personal data</li>
<li>Object to the processing of your personal data</li>
<li>Request that we restrict the processing of your personal data</li>
<li>Withdraw your consent to the processing of your personal data</li>
</ul>


  <h3>Updates to this privacy notice</h3>
  <p>
    We may update this privacy notice from time to time to reflect changes in our practices or legal obligations. We encourage you to review this privacy notice periodically.
  </p>
  <h3>Contact us</h3>
  <p>
    If you have any questions or concerns about this privacy notice, or if you would like to exercise any of your rights, please contact us at <Email email="info@dutchtaxcalculator.nl">📧 Email </Email>; 
  </p>
  <p>
    If you wish to exercise any of these rights, please contact us using the contact details provided above.
  </p>
  </div>
    </Layout>
  );
};

export default Blog;
